<script>
import { CalendarIcon, ChevronDown, CloseCircle, DocumentIcon, IconFilter, IconNewTab, PlusCircle, StoreLarge } from "@/icons";
import { LOAD_CURRENT_STORE_INVENTORIES } from "@/store/actions.type";
import { mapGetters, mapState } from 'vuex';
import DateRangeFilter from "@/views/payouts/components/DateRangeFilter.vue";
import moment from "moment";
import PageHeader from "@/views/payouts/components/PageHeader.vue";
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";
import SelectFilter from "@/views/payouts/components/SelectFilter.vue";
import ViewPayoutDialog from "@/views/payouts/components/ViewPayoutDialog.vue";
import SourceTabs from '@/views/payouts/components/SourceTabs.vue'
import SourcePayoutsCheckbox from '@/views/payouts/components/SourcePayoutsCheckbox.vue';
var Nanobar = require('../../../../../node_modules/nanobar/nanobar');
let nanobar = new Nanobar();
import Pagination from '@/components/UI/Pagination.vue'

export default {
  name: "SourcePayouts",

  mixins: [payoutsMixin],

  data() {
    return {
      filteredStoreOptions: [],
      isOrderFilterVisible: false,
      isSearchingStore: false,
      isStoreFilterVisible: false,
      ordersFilterOptions: [
        { key: 'orders_status', value: 'paid', label: 'Paid' },
        { key: 'orders_status', value: 'payout_created', label: 'Unpaid' },
      ],
      storeSearchTerm: "",
      loadingSourcePayouts: false
    }
  },

  components: {
    CalendarIcon,
    ChevronDown,
    CloseCircle,
    DateRangeFilter,
    DocumentIcon,
    IconFilter,
    IconNewTab,
    PageHeader,
    PlusCircle,
    SelectFilter,
    StoreLarge,
    ViewPayoutDialog,
    SourceTabs,
    SourcePayoutsCheckbox,
    Pagination,
  },

  computed: {
    ...mapState("shop", ["connectedShops"]),
    ...mapGetters("shop", ["currentShop"]),

    storeFilterOptions() {
      return this.connectedShops.map(shop => {
        return {
          key: "target_store",
          text: shop.store_domain,
          value: shop.id,
          commission: shop.store_commission
        }
      });
    },
  },

  async created() {
    try {
      await this.$store.dispatch(`shop/init`).then(() => {
        if (this.currentShop != null) {
          this.$store.dispatch(`shop/${LOAD_CURRENT_STORE_INVENTORIES}`);
        }
      });

      this.loadingSourcePayouts = true

      this.filteredStoreOptions = [...this.filteredStoreOptions, ...this.storeFilterOptions];
      this.filteredStoreOptions = this.arrayToObject(this.filteredStoreOptions, "value");
      this.setDefaultStartAndEndDate();
      this.$store.dispatch("payouts/fetchDashboardStats", this.currentShop.id);
      await this.fetchSourcePayouts();
      this.loadingSourcePayouts = false
    } catch(error) {
      console.log(error);
      this.loadingSourcePayouts = false
    }
  },

  methods: {
    setPayoutFilters() {
      let dateRange = "";
      this.filters.forEach(filter => {
        if(Object.prototype.hasOwnProperty.call(filter, "date_range")) {
          dateRange = filter.date_range;
        }
      });

      this.filters = [];
      const { orders_status, target_store } = this.sourcePayoutsFilters;

      if(target_store !== "All Stores") {
        this.filters.push({ origin_store: target_store.value });
      }

      this.filters.push({ status: orders_status });

      if(this.searchTerm !== "") {
        this.filters.push({ payout_id: this.searchTerm });
      }

      const { endDate, startDate } = this.dateRange;
      let formattedStartDate = moment(startDate || dateRange.split("to")[0].trim()).format("YYYY-MM-DD");
      let formattedEndDate = moment(endDate || dateRange.split("to")[1].trim()).format("YYYY-MM-DD");
      this.filters.push({ date_range: `${formattedStartDate} to ${formattedEndDate}` });
    },

    async fetchSourcePayouts(page) {
      nanobar.go(this.randomNumber(20, 90));
      this.setPayoutFilters();
      await this.$store.dispatch("payouts/fetchSourcePayouts", {
        current_store_id: this.currentShop.id,
        filters: this.filters,
        limiter: this.limiter,
        page
      });
      nanobar.go(100);
    },

    async onDateRangeSelectedHandler(value) {
      const { label } = value;
      this.sourcePayoutsFilters.date_range = label;
      this.dateRange.startDate = value.startDate;
      this.dateRange.endDate = value.endDate;
      await this.fetchSourcePayouts();
      this.sourcePayoutsCurrentPage = 1;
    },

    async filterByLatestPayoutsHandler() {
      this.sourcePayoutsFilters.date_range = "Last 30 days";
      this.setDefaultStartAndEndDate();
      await this.fetchSourcePayouts();
      this.sourcePayoutsCurrentPage = 1;
    },

    swapItems() {
      this.isSearchingStore = true;
      document.querySelector(".search-input").focus();
    },

    onClickOutsideStoreFilter() {
      this.isStoreFilterVisible = false;
    },

    onClickOutsideOrderFilter() {
      this.isOrderFilterVisible = false;
    },

    onSearchStoreHandler(event) {
      this.filteredStoreOptions = this.arrayToObject(this.storeFilterOptions.filter(store => store.text.includes(event.target.value)), "value");
    },

    async applyStoreFilter(filter) {
      this.isStoreFilterVisible = false;
      this.sourcePayoutsFilters.target_store = filter;
      this.selectedRecordStore = filter;
      await this.fetchSourcePayouts();
      this.sourcePayoutsCurrentPage = 1;
      this.bulkSourcePayouts = []
    },

    closeFilters() {
      this.isOrderFilterVisible = false;
      this.isStoreFilterVisible = false;
    },

    async applyFilter({ key, value }) {
      this.sourcePayoutsFilters[key] = value;
      this.closeFilters();
      await this.fetchSourcePayouts();
      this.sourcePayoutsCurrentPage = 1;
      this.bulkSourcePayouts = []
    },

    async filterByPayoutsToConfirmHandler() {
      const payoutsToConfirm = { key: "orders_status", value: "paid" };
      await this.applyFilter(payoutsToConfirm);
      this.sourcePayoutsCurrentPage = 1;
    },

    async searchOrders() {
      await this.fetchSourcePayouts();
      this.sourcePayoutsCurrentPage = 1;
      this.bulkSourcePayouts = []
    },

    async onClearFiltersHandler() {
      this.sourcePayoutsFilters.orders_status = "not_confirmed";
      this.searchTerm = "";
      this.setDefaultStartAndEndDate();
      this.sourcePayoutsFilters.date_range = "Last 30 days";
      this.sourcePayoutsFilters.target_store = "All Stores";
      this.selectedRecordStore = { text: "All Stores" };
      await this.fetchSourcePayouts();
      this.sourcePayoutsCurrentPage = 1;
    },

    async confirmPayoutHandler(payout) {
      await this.$store.dispatch("payouts/confirmPayout", {
        current_store_id: this.currentShop.id,
        payout_id: payout.payout_id
      });

      await this.$store.dispatch(`shop/loadCurrentShop`);
      this.$router.push({ name: 'CompleteSourcePayouts' })
      this.bulkSourcePayouts = []
    },

    async bulkConfirmPayoutHandler() {
      await this.$store.dispatch("payouts/confirmPayout", {
        current_store_id: this.currentShop.id,
        payout_id: this.bulkSourcePayouts
      });

      await this.$store.dispatch(`shop/loadCurrentShop`);
      this.$router.push({ name: 'CompleteSourcePayouts' })
      this.bulkSourcePayouts = []
    },

    paginationHandler(page) {
      this.fetchSourcePayouts(page);
    },

    shouldPayoutConfirmVisible(payout) {
      return payout.status === 'paid' || payout.status === 'payout_created'
    },

    updateCurrentPageHandler(page) {
      this.sourcePayoutsCurrentPage = page
      this.fetchSourcePayouts(this.sourcePayoutsCurrentPage);
    }
  }
}
</script>

<template>
  <section class="page payouts-page">
    <PageHeader pageDescription="Manage Payouts for fulfilled orders">
      <template #title>Manage payouts</template>
    </PageHeader>

    <SourceTabs />

    <!-- Filters -->
    <section class="filters-listing">
      <ul class="d-flex pa-0 justify-start">
        <li class="filters-listing__filter" data-filter="stores" @click.capture="isStoreFilterVisible = true" v-click-outside="onClickOutsideStoreFilter">
          <SelectFilter>
            <template #iconBefore>
              <StoreLarge />
            </template>
            <template #label>
              <div class="label" @click="swapItems">
                <input type="text" v-model="storeSearchTerm" v-show="isStoreFilterVisible" class="search-input" @keyup="onSearchStoreHandler">
                <span v-show="!isStoreFilterVisible">{{ sourcePayoutsFilters.target_store.text || sourcePayoutsFilters.target_store }}</span>
              </div>
            </template>
            <template #iconAfter>
              <div class="icon-right absolute">
                <ChevronDown />
              </div>
            </template>
            <template #options>
              <transition name="slide-x-reverse-transition">
                <ul v-if="isStoreFilterVisible" class="filter-options on-left">
                  <li
                    :class="{ 'active': sourcePayoutsFilters.target_store.text === filteredStoreOptions[key].text }"
                    :key="filteredStoreOptions[key].value"
                    @click.stop="applyStoreFilter(value)"
                    v-for="(value, key) in filteredStoreOptions"
                    v-show="Object.keys(filteredStoreOptions).length > 0">
                      {{ filteredStoreOptions[key].text }}
                  </li>
                  <li class="not-found" key="store-not-found" v-if="Object.keys(filteredStoreOptions).length === 0">Store not found</li>
                </ul>
              </transition>
            </template>
          </SelectFilter>
        </li>

        <li class="filters-listing__filter" data-filter="calendar">
          <SelectFilter>
            <template #iconBefore>
              <CalendarIcon />
            </template>
            <template #label>
              <div class="label">{{ sourcePayoutsFilters.date_range || "Select Range"  }}</div>
            </template>
            <template #iconAfter>
              <div class="icon-right absolute">
                <ChevronDown />
              </div>
            </template>
            <DateRangeFilter opens="right" :timeZone="timeZone" @onDateRangeSelected="onDateRangeSelectedHandler" :startDate="String(dateRange.startDate)" :endDate="String(dateRange.endDate)" />
          </SelectFilter>
        </li>

        <li class="filters-listing__filter" data-filter="orders" @click="isOrderFilterVisible = true" v-click-outside="onClickOutsideOrderFilter" style="width: 100px !important;">
          <SelectFilter>
            <template #iconBefore>
              <IconFilter />
            </template>
            <template #label>
              <div class="label">Payment status<span v-if="sourcePayoutsFilters.orders_status"></span>
                <span v-if="sourcePayoutsFilters.orders_status === 'not_confirmed'"></span>
                <span v-else-if="sourcePayoutsFilters.orders_status === 'payout_created'">: Unpaid</span>
                <span v-else>: {{ sourcePayoutsFilters.orders_status | removeUnderscore }}</span>
              </div>
            </template>
            <template #iconAfter>
              <div class="icon-right absolute" style="height: 21px;">
                <PlusCircle />
              </div>
            </template>
            <template #options>
              <transition name="slide-x-reverse-transition">
                <ul class="filter-options" v-if="isOrderFilterVisible">
                  <li :class="{ 'active': sourcePayoutsFilters.orders_status === filter.value }" v-for="filter in ordersFilterOptions" :key="filter.value" @click.stop="applyFilter(filter)">
                    {{ filter.label }}
                  </li>
                </ul>
              </transition>
            </template>
          </SelectFilter>
        </li>

        <li class="right-action-container">
          <button class="btn-icon d-flex align-center clear-filters-btn" @click="onClearFiltersHandler">
            <CloseCircle /> <span class="btn-icon--text">Clear Filters</span>
          </button>
        </li>
      </ul>
    </section>

    <div class="d-flex align-center my-4" v-if="bulkSourcePayouts.length > 0">
      <h4 class="mr-4" style="font-size: 16px;">{{ bulkSourcePayouts.length }} {{ bulkSourcePayouts.length > 1 ? 'payouts' : 'payout' }} selected</h4>
      <v-btn elevation="0" class="btn btn-border lg btn-success" @click="bulkConfirmPayoutHandler">Mark as Payment received</v-btn>
    </div>

    <!-- Tables -->
    <section class="table payouts-table fixed-layout">
      <v-simple-table>
        <template>
          <thead>
            <tr>
              <th style="width: 3%;"></th>
              <th style="width: 12.5%;">Date (AEST)</th>
              <th style="width: 7.5%;">Payout ID</th>
              <th style="width: 25%;">Store Name</th>
              <th style="width: 9%">Amount</th>
              <th style="width: 12.5%;">Payment Status</th>
              <th style="width: 30%;" class="text-right">Actions</th>
            </tr>
          </thead>
          <tbody v-if="sourcePayouts && sourcePayouts.payouts.length === 0 && sourcePayouts.payoutsToConfirm === 0 && totalSourcePayouts === 0">
            <tr class="no-hover">
              <td colspan="7" class="text-center empty-table-message">
                <p><strong>You have no payouts to review at this time.</strong></p>
                <p>Payouts will show when you mark a pushed order as fulfilled and <br/> when your destination store partner creates a payout.</p>
                <p class="mb-0 v2 pb-0">
                  <a href="https://help.syncio.co/en/articles/6402438-payouts-add-on-source-store-side" target="_blank" class="link link-underline">
                    Learn more about payouts
                    <IconNewTab />
                  </a>
                </p>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="payout in sourcePayouts.payouts" :key="payout.payout_id">
              <td>
                <SourcePayoutsCheckbox :payout="payout" v-if="shouldPayoutConfirmVisible(payout)" />
              </td>
              <td>{{ payout.date }}</td>
              <td>{{ payout.payout_id }}</td>
              <td>{{ payout.store_name }}</td>
              <td class="tabular-fonts"><span v-if="payout.payout_total < 0">-$</span>{{ payout.payout_total | currencyFormatter(currency) }}</td>
              <td>
                <span v-if="payout.status === 'payout_created'" class="status unpaid">Unpaid</span>
                <span v-else class="status" :class="payout.status">{{ payout.status | removeUnderscore }}</span>
              </td>
              <td>
                <div class="d-flex justify-end">
                  <v-btn @click="confirmPayoutHandler(payout)" elevation="0" class="btn btn-border btn-success" v-if="shouldPayoutConfirmVisible(payout)" :disabled="!shouldPayoutConfirmVisible(payout)">
                    <svg width="21" height="21" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-1">
                      <path d="M9.49992 18.2501C9.39921 18.2467 9.30025 18.2228 9.20911 18.1798C9.11798 18.1368 9.03658 18.0757 8.96992 18.0001L3.96992 13.0001C3.87458 12.9383 3.79454 12.8556 3.73583 12.7584C3.67712 12.6611 3.64127 12.5517 3.631 12.4386C3.62073 12.3254 3.6363 12.2114 3.67654 12.1052C3.71677 11.9989 3.78062 11.9032 3.86326 11.8252C3.94591 11.7473 4.04518 11.6891 4.1536 11.6551C4.26201 11.6211 4.37673 11.6122 4.48909 11.6291C4.60145 11.6459 4.70852 11.6881 4.80221 11.7523C4.8959 11.8166 4.97376 11.9013 5.02992 12.0001L9.49992 16.4401L19.9699 6.00009C20.1109 5.9087 20.2784 5.86728 20.4457 5.88247C20.613 5.89765 20.7704 5.96857 20.8926 6.08385C21.0148 6.19913 21.0947 6.3521 21.1196 6.51823C21.1445 6.68437 21.1129 6.85405 21.0299 7.00009L10.0299 18.0001C9.96325 18.0757 9.88185 18.1368 9.79072 18.1798C9.69958 18.2228 9.60062 18.2467 9.49992 18.2501Z" fill="#fff"/>
                    </svg>
                    <span class="ml-2">Payment received</span>
                  </v-btn>
                  <v-btn elevation="0" class="btn btn-border ml-3" @click="viewPayoutHandler(payout, 'source')" :disabled="isViewPayoutRecordLoading">
                    <DocumentIcon/> <span class="ml-3">View Payout</span>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </section>

    <Pagination :pagination="sourcePayouts.pagination" @updateCurrentPage="updateCurrentPageHandler" class="v2 bt-0" />

    <!-- View Payout Dialog -->
    <ViewPayoutDialog v-if="isViewPayoutDialogVisible" />
  </section>
</template>