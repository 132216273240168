<template>
  <ul class="tabs pa-0">
    <router-link to="/source-payouts" tag="li" exact class="tabs__item active">Open Payouts</router-link>
    <router-link to="/source-payouts/complete" exact tag="li" class="tabs__item">Complete</router-link>
  </ul>
</template>

<script>
export default {
  name: 'SourceTabs',
}
</script>
